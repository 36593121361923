<template>
  <div>
    <!-- Alert: No item found -->
    <b-alert
      variant="danger"
      :show="styleData === undefined"
    >
      <h4 class="alert-heading">
        Error fetching style data
      </h4>
      <div class="alert-body">
        No style found with this id. Check
        <b-link
          class="alert-link"
          :to="{ name: 'apps-styles-list'}"
        >
          Styles
        </b-link>
        list.
      </div>
    </b-alert>
    <template v-if="styleData">
      <b-row>
        <b-col
          cols="12"
        >
          <b-card>
            <div class="d-flex justify-content-between">
              <div class="d-flex mt-1 mb-1 justify-content-start">
                <div class="mr-5">
                  <b-media
                    left-align
                    vertical-align="center"
                  >
                    <template #aside>
                      <b-img
                        v-if="styleData.cover_image"
                        rounded
                        height="64"
                        :src="styleData.cover_image"
                      />
                    </template>
                    <h4 class="card-title mt-0 mb-1">
                      {{ styleName }}
                    </h4>
                    <h6 class="card-subtitle text-muted">
                      {{ styleRef }}
                    </h6>
                    <h6
                      v-if="styleData.collection"
                      class="card-subtitle mt-0"
                    >
                      {{ styleData.collection_name }}
                    </h6>
                  </b-media>
                </div>
                <div class="mr-5">
                  <h5 class="mt-0 mb-1 text-muted">
                    Category
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ categoryText(styleData.category) }}
                  </h6>
                </div>
                <div class="mr-5">
                  <h5 class="mt-0 mb-1 text-muted">
                    Client
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ styleData.client_name }}
                  </h6>
                </div>
                <div>
                  <h5 class="mt-0 mb-1 text-muted">
                    Status
                  </h5>
                  <h6 class="card-subtitle ">
                    {{ isClient ? 'Draft' : 'Published' }}
                  </h6>
                </div>
              </div>
            </div>
          </b-card>
        </b-col>
      </b-row>

      <!-- Main info -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-copy-main
            :style-ref.sync="styleRef"
            :style-name.sync="styleName"
          />
        </b-col>
      </b-row>
      <!-- Materials -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-copy-materials
            :style-data="styleData"
            :materials.sync="materials"
          />
        </b-col>
      </b-row>
      <!-- Embellishments -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-copy-embellishments
            :style-data="styleData"
            :embellishments.sync="embellishments"
          />
        </b-col>
      </b-row>
      <!-- Files -->
      <b-row>
        <b-col
          cols="12"
        >
          <style-copy-files
            :style-media="styleMedia"
            :is-client="isClient"
            :files.sync="files"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col
          cols="12"
        >
          <b-button
            variant="primary"
            class="mb-1 mb-sm-0 mr-0 mr-sm-1"
            type="submit"
            @click="onCopy"
          >
            Copy Style
          </b-button>
        </b-col>
      </b-row>
    </template>
  </div>
</template>

<script>
import store from '@/store'
import router from '@/router'
import {
  computed, ref, onMounted, onUnmounted,
} from '@vue/composition-api'
import {
  BAlert, BButton, BCard, BCol, BImg, BLink, BMedia, BRow,
} from 'bootstrap-vue'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import StyleCopyEmbellishments from './StyleCopyEmbellishments.vue'
import StyleCopyFiles from './StyleCopyFiles.vue'
import StyleCopyMain from './StyleCopyMain.vue'
import StyleCopyMaterials from './StyleCopyMaterials.vue'
import styleStoreModule from '../styleStoreModule'

export default {
  components: {
    BAlert,
    BButton,
    BCard,
    BCol,
    BImg,
    BLink,
    BMedia,
    BRow,

    StyleCopyMain,
    StyleCopyMaterials,
    StyleCopyEmbellishments,
    StyleCopyFiles,
  },
  setup(props, { root }) {
    const toast = useToast()

    const styleData = ref(null)
    const styleMedia = ref([])
    const materials = ref([])
    const embellishments = ref([])
    const files = ref([])
    const styleRef = ref('')
    const styleName = ref('')
    const userRole = JSON.parse(localStorage.getItem('userData')).role
    const isClient = userRole === 'client-admin' || userRole === 'client'

    const STYLE_APP_STORE_MODULE_NAME = 'app-style'

    // Register module
    if (!store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
      store.registerModule(STYLE_APP_STORE_MODULE_NAME, styleStoreModule)
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(STYLE_APP_STORE_MODULE_NAME)) {
        store.unregisterModule(STYLE_APP_STORE_MODULE_NAME)
      }
    })

    const categoryOptions = computed(() => {
      const options = root.$store.getters['app-style/categoryOptions']
      return options
    })
    const categoryText = computed(() => category => {
      const found = Array.from(categoryOptions.value)
        .find(option => option.value === category)
      return found ? found.label : category
    })
    const clientOptions = computed(() => {
      const options = root.$store.getters['app-style/clientOptions']
      return options
    })
    const brandOptions = computed(() => {
      const options = root.$store.getters['app-style/brandOptions']
      return options
    })
    const collectionOptions = computed(() => {
      const options = root.$store.getters['app-style/collectionOptions']
      return options
    })
    const clientContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/clientContactUserOptions']
      return options
    })
    const supplierContactUserOptions = computed(() => {
      const options = root.$store.getters['app-style/supplierContactUserOptions']
      return options
    })

    const optionsInit = () => {
      root.$store.dispatch('app-style/fetchFormOptions')
    }

    const fetchStyle = () => {
      store.dispatch('app-style/fetchStyle', { id: router.currentRoute.params.id })
        .then(response => {
          styleData.value = response.data
          styleRef.value = `Copy of ${styleData.value.ref}`
          styleName.value = `Copy of ${styleData.value.name}`
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleData.value = undefined
            styleRef.value = ''
            styleName.value = ''
          }
        })
    }
    const fetchStyleMedia = () => {
      store.dispatch('app-style/fetchStyleMedia', { id: router.currentRoute.params.id })
        .then(response => {
          styleMedia.value = response.data.style_media
          files.value = styleMedia.value.map(item => item.id)
        })
        .catch(error => {
          if (error.response.status === 404) {
            styleMedia.value = []
          }
        })
    }

    const onCopy = () => {
      const styleFormData = new FormData()
      styleFormData.append('style', router.currentRoute.params.id)
      styleFormData.append('ref', styleRef.value)
      styleFormData.append('name', styleName.value)
      styleFormData.append('files', files.value)
      styleFormData.append('materials', materials.value)
      styleFormData.append('embellishments', embellishments.value)
      store.dispatch('app-style/copyStyle', styleFormData)
        .then(response => {
          toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Style copied',
              icon: 'CopyIcon',
              variant: 'success',
            },
          })
          router.push({ name: 'apps-styles-edit', params: { id: response.data.id } })
        })
    }

    onMounted(optionsInit)
    onMounted(fetchStyle)
    onMounted(fetchStyleMedia)

    return {
      styleData,
      styleRef,
      styleName,
      styleMedia,
      userRole,
      isClient,
      materials,
      embellishments,
      files,

      categoryOptions,
      categoryText,
      clientOptions,
      brandOptions,
      collectionOptions,
      clientContactUserOptions,
      supplierContactUserOptions,
      onCopy,
    }
  },
}
</script>

<style>

</style>
