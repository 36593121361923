<template>
  <div>
    <b-card
      title="Files"
    >
      <b-card-text>
        <b-form-checkbox-group
          id="files"
          :checked="files"
        >
          <b-container>
            <b-row>
              <template
                v-for="media in styleMedia"
              >
                <b-col
                  v-if="!(isClient && media.is_internal) &&
                    !(media.style_embellishment || media.style_material)"
                  :key="media.id"
                  no-body
                  class="file-card-box"
                >
                  <b-card>
                    <b-card-text
                      text-tag="div"
                    >
                      <div :class="media.is_cover ? 'image-file iscover' : 'image-file'">
                        <b-img
                          v-if="media.image"
                          :alt="`${media.name}`"
                          rounded
                          :src="media.image"
                        />
                        <b-link
                          v-if="media.file"
                          target="blank"
                          class="nopreview"
                          :href="media.file"
                        >{{ media.name }}</b-link>
                        <b-img
                          v-if="media.static_file"
                          :alt="`${media.name}`"
                          rounded
                          height="160"
                          :src="media.static_file"
                        />
                      </div>
                      <div class="mt-1 d-flex flex-row">
                        <!-- Tag -->
                        <b-badge
                          v-for="tag in splitTag(media.tag)"
                          :key="tag"
                          pill
                          variant="secondary"
                          class="mt-1 mb-1"
                        >{{ tag }}</b-badge>

                        <!-- Internal -->
                        <b-badge
                          v-if="media.is_internal"
                          pill
                          variant="primary"
                          class="mt-1 mb-1"
                        >
                          Internal
                        </b-badge>
                      </div>
                    </b-card-text>
                    <b-card-text
                      align="center"
                    >
                      <b-form-checkbox
                        :key="`check-${media.id}`"
                        :value="media.id"
                        :disabled="media.is_cover"
                        @change="(val) => $emit('update:files', val)"
                      />
                    </b-card-text>
                  </b-card>
                </b-col>
              </template>
            </b-row>
          </b-container>
        </b-form-checkbox-group>
      </b-card-text>
    </b-card>
  </div>
</template>

<script>
import {
  BBadge, BCard, BCardText, BCol, BContainer, BFormCheckbox, BFormCheckboxGroup,
  BImg, BLink, BRow,
} from 'bootstrap-vue'

export default {
  components: {
    BBadge,
    BCard,
    BCardText,
    BCol,
    BContainer,
    BFormCheckbox,
    BFormCheckboxGroup,
    BImg,
    BLink,
    BRow,
  },
  props: {
    styleMedia: {
      type: Array,
      required: true,
    },
    isClient: {
      type: Boolean,
      required: true,
    },
    files: {
      type: Array,
      required: true,
    },
  },
  setup() {
    const splitTag = tag => {
      if (!tag) {
        return []
      }
      return tag.split(',')
    }
    return {
      splitTag,
    }
  },
}
</script>
<style lang="scss" scoped>
.file-card-box {
  flex: 0 1 300px;
  overflow:hidden;
}
.image-file img {
  object-fit: cover;
  width: 100%;
  height: 180px;
  vertical-align: middle;
  border-radius: 15px;
}
.image-file .nopreview {
  object-fit: cover;
  width: 100%;
  height: 180px;
  display:flex;
}
.noimage-file {
  margin: 0 auto 0 auto;
  height: 180px;
  border-radius: 15px;
}
.iscover {
  outline:10px solid;
  border-radius: 15px;
}
</style>
